import React, { useEffect, useState } from 'react';

interface UseCountUpProps {
  end: number;
  duration?: number;
}

const useCountUp = ({ end, duration = 2000 }: UseCountUpProps): number => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let startTime: number | null = null;

    const step = (timestamp: number) => {
      if (!startTime) {
        startTime = timestamp;
      }
      const progress = Math.min((timestamp - startTime) / duration, 1);

      setCount(Math.floor(progress * end));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);

    return () => {
      startTime = null;
    };
  }, [end, duration]);

  return count;
};

interface StatItemProps {
  value: number;
  label: string;
}

const StatItem: React.FC<StatItemProps> = ({ value, label }) => {
  const animatedValue = useCountUp({ end: value });

  return (
    <div className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md">
      <div className="mt-4 text-5xl font-bold text-gray-900">{animatedValue || value}</div>
      <div className="mt-2 text-xl text-gray-500 text-center">{label}</div>
    </div>
  );
};

export default StatItem;
